import { GetStaticPaths, GetStaticPropsResult, NextPage } from 'next/types'
import {
    FooterIH,
    HeaderIH,
    PostSponsoredData,
    SearchIH
} from '../../../libs/interfaces'
import HeaderComponent from '../../../libs/components/header'
import { Body } from '../../../libs/components/header/Header.styled'
import { GLOBAL_CONSTANT } from '../../../libs/utils/constants'
import { FooterComponent, PaginationDS } from '../../../libs/components'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { BREAKPOINTS } from '../../../libs/utils/constants/layout'
import { PaginationContainer } from '../../../libs/style/search.style'
import styled from 'styled-components'
import { tokens } from '@JOTAJornalismo/jota-design-system'
import { LoadingTagPage } from '@App/libs/components/loading/Loading.component'
import ListComponent from '@App/libs/components/list/ListComponent'
import Metadata from '@App/libs/components/Metadata.component'
import BreadcrumbComponentJU, {
    BreadHome
} from '@App/libs/components/ds/Breadcrumb'
const { colors, fonts, spaces } = tokens

const SearchPage: NextPage<{
    header: HeaderIH
    footer: FooterIH
    search: SearchIH[]
    total: any
}> = ({ header, footer }) => {
    const router = useRouter()
    const [pageTitle, setPageTitle] = useState<string>()

    const [tagDescription, setTagDescription] = useState<string>()

    const [totalPages, setTotalPages] = useState<number>(0)
    const [page, setPage] = useState<number>(1)
    const [posts, setPosts] = useState<PostSponsoredData[]>([])

    const [finishGetPosts, setFinishGetPosts] = useState<boolean>(false)
    const [finishGetContent, setFinishGetContent] = useState<boolean>(false)

    const tagByRoute = router?.query?.tag as string
    const pageFromParams = router.asPath.split('=')[1]

    const [show, setShow] = useState<boolean>(false)

    const getPosts = async (currentPage: number) => {
        const url = `${process.env.NEXT_PUBLIC_POSTS_BY_TAG}?slug=${tagByRoute}&page=${currentPage}`

        const response = await fetch(url)
        const posts = await response.json()
        setPage(currentPage)

        try {
            setTotalPages(posts.total_pages)
            setPosts(posts.posts)
            setFinishGetPosts(true)
            setPageTitle(posts.tag_data.title)
            setTagDescription(posts.tag_data.description)
            setFinishGetContent(true)
        } catch (error) {
            alert(error)
        }
    }

    useEffect(
        () => {
            if (pageFromParams) {
                getPosts(parseInt(pageFromParams))

                setTimeout(() => {
                    setShow(true)
                }, 500)
            } else {
                getPosts(1)

                setTimeout(() => {
                    setShow(true)
                }, 500)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [pageFromParams]
    )

    useEffect(() => {
        setTimeout(() => {
            setShow(true)
        }, 500)
    }, [pageTitle])

    return (
        <div className='page' data-page-type='tag'>
            <Metadata
                title={`JOTA - ${pageTitle}`}
                description={tagDescription ?? GLOBAL_CONSTANT.SITE_DESCRIPTION}
                tag={`conteudo, notícias, ${pageTitle?.toLowerCase()}`}
                breadcrumb={[
                    BreadHome,
                    ...(pageTitle
                        ? [
                              {
                                  label: pageTitle,
                                  slug: router.asPath
                              }
                          ]
                        : [])
                ]}
                url={`https://www.jota.info/tudo-sobre/${tagByRoute}`}
            />
            <Body>
                {header && show && (
                    <HeaderComponent
                        wpMenu={header.menu}
                        ctaPRO={header.pro}
                        showAcessibility={header.enableAccessibility}
                        signupLink={header.assinePage}
                    />
                )}
                {tagByRoute && pageTitle && show && (
                    <EditoriaHeader>
                        <BreadcrumbComponentJU
                            items={[
                                BreadHome,
                                {
                                    label: 'Tudo sobre',
                                    slug: 'tudo-sobre',
                                    clickable: false
                                },
                                {
                                    label: pageTitle,
                                    slug: router.asPath,
                                    clickable: false
                                }
                            ]}
                        />

                        <EditoriaTitle>#{pageTitle}</EditoriaTitle>
                        <TagDescription> {tagDescription} </TagDescription>
                    </EditoriaHeader>
                )}

                {posts.length > 0 && finishGetPosts && show && (
                    <>
                        <JOTAPROgrid>
                            <ListComponent content={posts} type='wp' />
                        </JOTAPROgrid>

                        <PaginationContainer>
                            <PaginationDS
                                currentPage={page}
                                totalPages={totalPages}
                                handleChange={(e: any, currentPage: number) => {
                                    setPage(currentPage)
                                    getPosts(currentPage)

                                    if (!tagByRoute.includes('page')) {
                                        window.open(
                                            `/tudo-sobre/${tagByRoute}?page=${currentPage}`,
                                            '_self'
                                        )
                                    } else if (tagByRoute.includes('page')) {
                                        window.open(
                                            `/tudo-sobre/${
                                                tagByRoute.split('&')[0]
                                            }?page=${currentPage}`,
                                            '_self'
                                        )
                                    }
                                }}
                                color='jotaOrange'
                            />
                        </PaginationContainer>
                    </>
                )}

                {!finishGetContent && !finishGetPosts && !show && (
                    <LoadingComponent>
                        {/* eslint-disable-next-line new-cap */}
                        {LoadingTagPage()}
                    </LoadingComponent>
                )}

                {footer && finishGetContent && finishGetPosts && show && (
                    <FooterComponent footer={footer} />
                )}
            </Body>
        </div>
    )
}

export default SearchPage

export const getStaticPaths: GetStaticPaths<{
    editoria: string
}> = async () => {
    return {
        paths: [],
        fallback: 'blocking'
    }
}

/**
 * getStaticProps
 * @param {unknow} context
 * @return {Promise<GetStaticPropsResult<HomePagePropsInterface>>}
 */
export async function getStaticProps(): Promise<GetStaticPropsResult<unknown>> {
    if (process?.env?.NEXT_PUBLIC_HEADER_AND_FOOTER) {
        const apiData = await (
            await fetch(process?.env?.NEXT_PUBLIC_HEADER_AND_FOOTER, {
                cache: 'no-cache'
            })
        ).json()

        const { header, footer } = apiData

        return {
            props: {
                header,
                footer
            }
        }
    }

    return {
        props: {}
    }
}

export const EditoriaTitle = styled.h1<{ isEmpty?: boolean }>`
    ${fonts.jotaHeading04};
    color: ${colors.black.jotaBlack};
    margin: ${spaces.jotaSpacing12} 0 0 0;
    padding-bottom: ${(props) => props.isEmpty && spaces.jotaSpacing8};

    border-bottom: ${(props) =>
        props.isEmpty && `1px solid ${colors.transparent.jotaTGrayLight40}`};

    @media only screen and (max-width: ${BREAKPOINTS.MD_PX}) {
        margin-top: ${spaces.jotaSpacing12};
    }
`

export const EditoriaHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-inline: 44px;
    padding-top: 44px;
    box-sizing: border-box;
    border-bottom: 1px solid ${colors.transparent.jotaTGrayLight40};
    z-index: -2;

    @media only screen and (max-width: ${BREAKPOINTS.MD_PX}) {
        flex-direction: column;
        align-items: flex-start;
        margin-inline: 36px;
    }

    @media only screen and (max-width: ${BREAKPOINTS.SM_PX}) {
        margin-inline: 16px;
        padding-top: 24px;
    }
`

const JOTAPROgrid = styled.div``

const LoadingComponent = styled.div`
    margin-inline: 44px;
`

const TagDescription = styled.div`
    ${fonts.jotaBodyLarge};
    color: ${colors.gray.jotaGrayDark};
    margin-block: 8px;
`
